<template>
    <v-content>
        <v-breadcrumbs :items="items" large></v-breadcrumbs>
        <div class="float-right mb-2">
            <router-link :to="'/user/list/1'">
                <v-btn color="primary" class="mr-2">Info/Import Data</v-btn>
            </router-link>
            <router-link :to="'/division/'+this.$route.params.id+'/immigration'">
                <v-btn color="primary">Export</v-btn>
            </router-link>
        </div>
        <v-expansion-panels hover>
            <v-expansion-panel>
                <v-expansion-panel-header>Filter</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-container fluid>
                        <v-row align="start">
                            <v-col class="float-right" cols="12" sm="12">
                                <v-text-field label="Brand Name" v-model="serverParams.BranchName"></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row align="center" justify="center">
                            <v-btn color="primary" dark @click="searchInput" class="mr-1">
                                <i class="material-icons">search</i>Search
                            </v-btn>
                            <v-btn color="error" dark @click="clearInput">
                                <i class="material-icons">clear</i>Clear
                            </v-btn>
                        </v-row>
                    </v-container>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <vue-good-table
                mode="remote"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-column-filter="onColumnFilter"
                @on-per-page-change="onPerPageChange"
                :totalRows="totalRecords"
                :isLoading.sync="isLoading"
                :pagination-options="{ enabled: true, }"
                :rows="rows"
                :columns="columns"
                theme="black-rhino"
        >

            <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'actions'">
             <v-chip
                     small
                     class="ml-2"
                     color="green"
                     text-color="white"
                     @click="loadServices(props.row)"
             >
             services
            </v-chip>
             <v-chip
                     small
                     class="ml-2 darken-2"
                     color="red"
                     text-color="white"
                     @click="loadDetails(props.row)"
             >
             Details
            </v-chip>
            </span>
            </template>
        </vue-good-table>
        <v-dialog
                v-model="snackbar.modal"
                transition="dialog-bottom-transition"
                max-width="790"
                scrollable
                persistent
                hide-overlay
        >

            <BranchServices @dialogueClose="dialogueClose"
                            :divisionName="branchData.divisionName"
                            :branchID="branchData.branchID"
                            v-if="snackbar.branchServices"
            ></BranchServices>
            <BranchDetails @dialogueClose="dialogueClose"
                           :divisionName="branchData.divisionName"
                           :branchID="branchData.branchID"
                           v-if="snackbar.branchDetails"
            ></BranchDetails>
        </v-dialog>
    </v-content>
</template>

<script>
    import axios from "axios";
    import {mapGetters} from 'vuex'
    import BranchServices from "../../components/servicesProvinces/BranchServices";
    import BranchDetails from "../../components/servicesProvinces/BranchDetails";

    export default {
        name: "List",
        watch: {
            "filterEditedData.provinceId": {
                handler: async function (val) {
                    if (val) {
                        this.division = [];
                        let url = "Division/GetDivisionListByProvinceId/" + val;
                        const divisionBranch = await axios.get(url);
                        this.division = divisionBranch.data.divisionList;
                    }
                },
                deep: true
            }
        },
        components: {
            BranchServices,
            BranchDetails,
        },
        data() {
            return {
                snackbar: {
                    modal: false,
                    branchServices: false,
                    branchDetails: false
                },
                branchData: {},
                isLoading: false,
                items: [],
                province: [],
                division: [],
                columns: [
                    {
                        label: "id",
                        field: "branchID"
                    },
                    {
                        label: "Brand Name",
                        field: "branchName"
                    },
                    {
                        label: "Brand Address",
                        field: "branchAddress"
                    },
                    {
                        label: "Brand Phone Number",
                        field: "branchPhoneNumber"
                    },
                    {
                        label: "Actions",
                        field: "actions"
                    }
                ],
                rows: [],
                totalRecords: 0,
                serverParams: {
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "branchID",
                            type: "asc"
                        }
                    ],
                    page: 1,
                    perPage: 10
                },
                filterEditedData: {
                    divisionId: "",
                    provinceId: "",
                    branchAddress: "",
                    branchCode: "",
                    branchName: "",
                    branchPhoneNumber: ""
                }
            };
        },
        mounted() {
            this.filterEditedData.divisionId = this.$route.params.id;
            this.loadItems();
            this.filterData();
        },
        computed: {
            ...mapGetters(['getSystemUserData'])
        },
        methods: {
            dialogueClose() {
                this.snackbar.modal = false
                this.snackbar.branchServices = false
                this.snackbar.branchDetails = false
            },
            loadDetails({divisionName, branchID}) {
                this.snackbar.modal = true
                this.snackbar.branchDetails = true
                this.branchData.divisionName = divisionName
                this.branchData.branchID = branchID
                console.log(this.branchData.divisionName, this.branchData.branchID)
            },
            loadServices({divisionName, branchID}) {
                this.snackbar.modal = true
                this.snackbar.branchServices = true
                this.branchData.divisionName = divisionName
                this.branchData.branchID = branchID
                console.log(this.branchData.divisionName, this.branchData.branchID)
            },
            async filterData() {
                const provinceRequest = await axios.post("Province/ProvinceList", {});
                this.province = provinceRequest.data.data;
            },

            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },

            searchInput() {
                this.loadItems();
            },
            clearInput() {
                this.serverParams.BranchName = null
                this.loadItems();
            },

            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({perPage: params.currentPerPage==-1?this.totalRecords:params.currentPerPage});
                this.loadItems();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [
                        {
                            type: params[0].type,
                            field: params[0].field
                        }
                    ]
                });
                this.loadItems();
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },

            async loadItems() {
                let param = {
                    DivisionId: parseInt(this.$route.params.divisionID),
                    ProvinceID:  parseInt(this.$route.params.provinceID),
                    IsFromDivision: true,
                    PageSize: this.serverParams.perPage,
                    PageNo: this.serverParams.page,
                    OrderType: this.serverParams.sort[0].type,
                    OrderBy: this.serverParams.sort[0].field,
                    BranchName: this.serverParams.BranchName,
                };
                try {
                    let list = await axios.post("Branch/BranchList", param);
                    console.log(list.data.provinceDivision);
                    this.items = [
                        {
                            text: "Dashboard",
                            disabled: false,
                            to: this.getSystemUserData.dashBoardBranchUser,
                            exact: true
                        },
                        {
                            text: list.data.provinceDivision.provinceName,
                            disabled: false,
                            to: {name: 'DivisionList', params: {provinceID: list.data.provinceDivision.provinceID}},
                            exact: true
                        },
                        {
                            text: list.data.provinceDivision.divisionName,
                            disabled: true,
                            to: "/branch/" + list.data.provinceDivision.divisionID,
                            exact: true
                        }
                    ];

                    this.rows = list.data.branchList.data;
                    this.totalRecords = list.data.branchList.totalCount;
                } catch (e) {
                    this.snackbar.message = e
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    .v-item-group {
        background: #d2e3ff73;
    }

    .v-expansion-panel-header {
        background: #d2e3ff73;
    }

    .eye {
        padding: 0.5em;
    }

    .view {
        text-decoration: none;
    }
</style>
